<template>
  <DoubleColumnLayout>
    <template v-slot:default>

      <v-container>
        <v-alert dismissible type="success" v-model="success"
          >Your message was sent successfully.</v-alert
        >
        <v-alert dismissible type="error" v-model="error"
          >Your message couldn't be sent. Please try again later.</v-alert
        >
        <h1>{{ $t("contact.caption") }}</h1>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="messageDto.name"
                name="name"
                :label="$t('contact.name.label')"
                required
                :rules="nameRules"
                :counter="NAME_MAX_LENGTH"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="messageDto.email"
                name="email"
                :label="$t('contact.email.label')"
                required
                :rules="emailRules"
                :counter="EMAIL_MAX_LENGTH"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="messageDto.subject"
                :label="$t('contact.subject.label')"
                required
                :rules="subjectRules"
                :counter="SUBJECT_MAX_LENGTH"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="messageDto.message"
                :label="$t('contact.message.label')"
                required
                :rules="messageRules"
              />
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="messageDto.attachment"
                :label="$t('contact.attachment.label', ['1MB'])"
                show-size
                :rules="fileRules"
              />
            </v-col>
            <v-col>
              <v-btn @click="submit">{{ $t("contact.send") }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>

    </template>

    <template v-slot:right>
    </template>

  </DoubleColumnLayout>

</template>

<script>
import DoubleColumnLayout from "@/components/DoubleColumnLayout.vue";
export default {
  name: "Contact",
  components: {
    DoubleColumnLayout,
  },
  data() {
    return {
      NAME_MAX_LENGTH: 30,
      EMAIL_MAX_LENGTH: 255,
      SUBJECT_MAX_LENGTH: 50,
      FILE_MAX_SIZE: 1048576,
      valid: true,
      success: false,
      error: false,
      messageDto: {
        name: null,
        email: null,
        subject: null,
        message: null,
        attachment: null,
      },
      nameRules: [
        (v) => !!v || this.$t("contact.name.rules.empty"),
        (v) =>
          (v && v.length <= this.NAME_MAX_LENGTH) ||
          this.$t("contact.name.rules.maxLength", [this.NAME_MAX_LENGTH]),
      ],
      emailRules: [
        (v) => !!v || this.$t("contact.email.rules.empty"),
        (v) =>
          (v && v.length <= this.EMAIL_MAX_LENGTH) ||
          this.$t("contact.email.rules.maxLength", [this.EMAIL_MAX_LENGTH]),
        (v) => /.+@.+\..+/.test(v) || this.$t("contact.email.rules.invalid"),
      ],
      subjectRules: [
        (v) => !!v || this.$t("contact.subject.rules.empty"),
        (v) =>
          (v && v.length <= this.SUBJECT_MAX_LENGTH) ||
          this.$t("contact.subject.rules.maxLength", [this.SUBJECT_MAX_LENGTH]),
      ],
      messageRules: [(v) => !!v || this.$t("contact.message.rules.empty")],
      fileRules: [
        (v) =>
          !v ||
          v.size <= this.FILE_MAX_SIZE ||
          this.$t("contact.attachment.rules.maxSize", ["1MB"]),
      ],
    };
  },
  methods: {
    async submit() {
      this.$refs.form.validate();

      if (!this.valid) {
        return;
      }

      var formData = new FormData();
      formData.append("name", this.messageDto.name);
      formData.append("email", this.messageDto.email);
      formData.append("subject", this.messageDto.subject);
      formData.append("message", this.messageDto.message);
      if (this.messageDto.attachment) {
        formData.append("attachment", this.messageDto.attachment);
      }

      var ok = true;

      try {
        const response = await this.$api.post("contact", formData);
        ok = response.data;
      } catch (e) {
        ok = false;
      }

      if (ok) {
        this.error = false;
        this.success = true;
        this.$refs.form.reset();
      } else {
        this.success = false;
        this.error = true;
      }
    },
  },
};
</script>
